import { createSlice } from "@reduxjs/toolkit";

const initialOrderState = {
  listLoading: false,
  detailLoading: false,
  actionsLoading: false,
  customerLoading: false,
  packagingLoading: false,
  totalCount: 0,
  entities: null,
  productForEdit: undefined,
  lastError: null,
  searchNew: [],
  cardDetail: [],
  searchProduct: { loading: false, data: [] },
  orderSummary: { loading: false, data: [], data_two: "" },
  storageData: {},
  quoteData: { loading: false },
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  orderDetail: {},
  editOrderDetail: {},
  timeline: [],
  cardDetailArray: [],
  billingCard: { loading: false },
  paymentInvoiceList: [],
  invoiceList: [],
  chargeBeeInvoiceList: [],
  orderDropDown: {},
  editOrderTable: {},
  orderInvoice: { loading: false, data: {} },
  driverLoader: { loading: false, data: {} },
  savedDriverLoader: { loading: false, data: {} },
  versionDetail: {},
  versionDetailLoading: false,
  packagingMaterial: {},
  bookingAccessCharges: {},
  cardDetailCheck: { loading: false },
  statsData: { loading: false },
  siteData: [],
  siteLoading: false,
  callApi: false,
  newFlagData: {},
  editOrderSummary: {},
  sub:false
};
export const callTypes = {
  list: "list",
  action: "action",
  detail: "detail",
  customer: "customer",
  packaging: "packaging",
  stats: "stats",
  version: "version",
  site: "site",
  callApi: "callApi",
  sub:"sub"
};

export const orderSlice = createSlice({
  name: "Order",
  initialState: initialOrderState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.detail) {
        state.detailLoading = false;
      } else if (action.payload.callType === callTypes.customer) {
        state.customerLoading = false;
      } else if (action.payload.callType === callTypes.packaging) {
        state.packagingLoading = false;
      } else if (action.payload.callType === callTypes.stats) {
        state.statsData.loading = false;
      } else if (action.payload.callType === callTypes.version) {
        state.versionDetailLoading = false;
      } else if (action.payload.callType === callTypes.callApi) {
        state.callApi = false;
      } else if (action.payload.callType === callTypes.site) {
        state.siteLoading = false;
      } else if (action.payload.callType === callTypes.sub) {
        state.sub = false;
      }else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.detail) {
        state.detailLoading = true;
      } else if (action.payload.callType === callTypes.customer) {
        state.customerLoading = true;
      } else if (action.payload.callType === callTypes.packaging) {
        state.packagingLoading = true;
      } else if (action.payload.callType === callTypes.stats) {
        state.statsData.loading = true;
      } else if (action.payload.callType === callTypes.callApi) {
        state.callApi = true;
      } else if (action.payload.callType === callTypes.version) {
        state.versionDetailLoading = true;
      } else if (action.payload.callType === callTypes.site) {
        state.siteLoading = true;
      } else if (action.payload.callType === callTypes.sub) {
        state.sub = true;
      }else {
        state.actionsLoading = true;
      }
    },
    // getProductById

    // findProducts
    orderFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    searchNewOrderFetched: (state, action) => {
      const { data } = action.payload;
      state.customerLoading = false;
      state.searchNew = data;
    },
    searchProductFetched: (state, action) => {
      const { data, loading } = action.payload;
      state.searchProduct.loading = loading;
      state.searchProduct.data = data;
    },
    orderSummaryFetched: (state, action) => {
      const { data, loading, data_two } = action.payload;
      state.orderSummary.loading = loading;
      state.orderSummary.data = data;
      state.orderSummary.data_two = data_two;
    },
    newCustomerFetched: (state, action) => {
      const { data, id, chargebee_key, stripe_pk } = action.payload;
      if (id) {
        state.cardDetail[0].contact.franchise.stripe_pk = stripe_pk;
        state.cardDetail[0].contact.franchise.chargebee_key = chargebee_key;
        state.cardDetail[0].contact.franchise_id = id;
        return;
      }
      state.cardDetail = data;
      state.detailLoading = false;
    },
    quoteData: (state, action) => {
      const { data, loading } = action.payload;
      state.quoteData = data;
      state.quoteData.loading = loading;
    },
    blankCustomer: (state, action) => {
      state.cardDetail = [];
      state.quoteData = {};
      state.orderSummary.loading = false;
      state.orderSummary.data = {};
      state.orderSummary.data_two = {};
    },
    storageData: (state, action) => {
      state.storageData = action.payload;
    },
    //my order list
    myOrderFetchedList: (state, action) => {
      const { totalCount, entities, listLoading } = action.payload;
      state.list.listLoading = listLoading;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },
    //order Detail
    OrderDetailFetched: (state, action) => {
      const { data } = action.payload;

      state.orderDetail = data;
    },
    //order edit Detail
    OrderEditDetailFetched: (state, action) => {
      const { data } = action.payload;

      state.editOrderDetail = data;
    },
    OrderTimelineFetched: (state, action) => {
      const { data } = action.payload;

      state.timeline = data;
    },
    CardDetail: (state, action) => {
      const { data, loading } = action.payload;
      state.cardDetailCheck.loading = loading;
      state.cardDetailArray = data;
    },
    BillingCardDetail: (state, action) => {
      const { data, loading } = action.payload;

      state.billingCard = data;
      state.billingCard.loading = loading;
    },
    PaymentInvoiceList: (state, action) => {
      const { data } = action.payload;

      state.paymentInvoiceList = data;
    },
    InvoiceList: (state, action) => {
      const { data } = action.payload;

      state.invoiceList = data;
    },
    ChargeBeeInvoiceList: (state, action) => {
      const { data } = action.payload;

      state.chargeBeeInvoiceList = data;
    },
    //OrderDropDown
    OrderDropDown: (state, action) => {
      const { data } = action.payload;

      state.orderDropDown = data;
    },
    OrderTableEdit: (state, action) => {
      const { data } = action.payload;

      state.editOrderTable = data;
    },
    OrderInvoice: (state, action) => {
      const { data, loading } = action.payload;

      state.orderInvoice.data = data;
      state.orderInvoice.loading = loading;
    },
    DriverLoader: (state, action) => {
      const { data, loading } = action.payload;

      state.driverLoader.data = data;
      state.driverLoader.loading = loading;
    },
    SavedDriverLoader: (state, action) => {
      const { data, loading } = action.payload;

      state.savedDriverLoader.data = data;
      state.savedDriverLoader.loading = loading;
    },
    cardDetailReset: (state, action) => {
      state.cardDetail = [];
    },
    orderReset: (state, action) => {
      state.orderDetail = {};
      state.timeline = [];
      state.cardDetailArray = [];
      state.billingCard = {};
      state.paymentInvoiceList = [];
      state.invoiceList = [];
      state.orderDropDown = {};
      state.editOrderTable = {};
    },
    versionDetail: (state, action) => {
      const { data } = action.payload;
      state.versionDetail = data;
      state.versionDetailLoading = false;
    },

    packagingMaterial: (state, action) => {
      const { data } = action.payload;
      state.packagingMaterial = data;
      state.packagingLoading = false;
    },

    bookingAccessCharges: (state, action) => {
      const { data } = action.payload;
      state.bookingAccessCharges = data;
    },
    statsSlice: (state, action) => {
      const { data } = action.payload;
      state.statsData.loading = false;
      state.statsData = data;
    },
    newFlagSlice: (state, action) => {
      const { data } = action.payload;
      state.newFlagData = data;
    },
    siteSlice: (state, action) => {
      const { arr } = action.payload;
      // state.statsData.loading = false;
      state.siteLoading = false;
      state.siteData = arr;
    },
    orderSummaryListStripe: (state, action) => {
      const { data } = action.payload;
      state.orderSummaryListStripe = data;
      state.callApi = false;
    },
    editOrderSummaryFetched: (state, action) => {
      const { data } = action.payload;
      state.editOrderSummary = data;
      state.sub = false;
    },
  },
});
