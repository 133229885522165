/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FormControl, InputGroup } from "react-bootstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Table } from "react-bootstrap";
import currencyApi from "../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../Settings/_redux/country/countryAction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  handleFinalPrice,
  handleFinalPriceCalculation,
  handleUpdateQtyPackaging,
  productArrayNewData,
  productArrayUpdated,
  rearrangeArray,
} from "../orderUtils";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  convertGBPtoPoundSymbol,
  insuranceCalculation,
  WeekCounter,
} from "../../../utils/commonFunction";
import { fetchOrderSummary } from "../_redux/order/orderAction";
import { CompetitorPriceCheckBox } from "../NewOrder/components/CompetitorPriceCheckBox";
import { debounce } from "lodash";
import moment from "moment";

export function OrderSummaryTable({
  quantityUpdate,
  rowDelete,
  orderData,
  orderDataTwo,
  handleDelete,
  loading,
  productArray,
  setProductArray,
  setTotalData,
  totalData,
  dispatchReducer,
  state,
  typeCheck,
  setQuantity,
  quantity,
  setParamData,
  paramData,
  location,
  secCheck,
  handleBilling,
}) {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [shipLoad, setShipLoad] = useState(false);
  const { detailData, cardDetail } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
      cardDetail: state.order.cardDetail,
    }),
    shallowEqual
  );

  const handleQty = (qty, index, id, item) => {


    if (item.type === "plan" && qty !== "") {
      if (state.containerTypeSelect === "container") {
        dispatchReducer({ type: "collectionRetrunEmpty", value: {} });
      }
      const insuranceCost = insuranceCalculation(qty);
      const coverList = state.productArray.find((e) => e.type === "addon");
      dispatchReducer({
        type: "cover",
        value: {
          cover: insuranceCost,
          price: coverList?.cover_list[insuranceCost] || 1,
        },
      });
      const data = {
        collection_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_collection_date : state?.collectionRetrun?.collection_date || "",
        pod: qty ? qty : 1,
        return_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_return_date :
          state.collectionRetrun?.return_date &&
            state.collectionRetrun?.return_start_time_slot
            ? state.collectionRetrun?.return_date
            : "",
        return_postcode:
          state.collectionRetrun?.return_postcode &&
            state.collectionRetrun?.return_start_time_slot
            ? state.collectionRetrun?.return_postcode
            : "",
        is_student: state.collectionRetrun?.is_student || 0,
        is_student_return: state.collectionRetrun?.is_student_return || 0,
        competitor_price: state.competitorPrice?.check
          ? state.competitorPrice?.price
          : 0,
        isCompetitor: state.competitorPrice?.check,
        container_size: state.containerListSelect,
        site_id: state?.siteSelect?.id,
        is_plan_replaced: state?.is_plan_replaced
        // container_plan:state.containerPlanListSelect
      };

      dispatch(
        fetchOrderSummary(
          state?.deletedProduct,
          "12",
          paramData.unit,
          paramData.period,
          paramData?.products_id,
          cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id
            ? cardDetail[0].contact?.franchise_id
            : "",
          cardDetail && cardDetail.length && cardDetail[0].postcode
            ? cardDetail[0].postcode
            : "",
          "",
          "",
          "",
          "",
          data
        )
      );
      if (qty > Number(state?.collectionRetrun?.max_pod)) {
        dispatchReducer({ type: "collectionRetrunEmpty", value: {} });
        dispatchReducer({ type: "depotDataForMap", value: {} });
      }
    }
    if (Number(qty) < 0) return;
    if (qty !== "" && Number(qty) === 0) return;
    const arr = [...state?.quantity];
    if (arr.some((e) => e.id === id)) {
      const ind = arr.findIndex((e) => e.id === id);

      arr[ind].qty = qty;
    } else {
      arr.push({ qty, i: index, id: id });
    }
    setQuantity(arr);
    dispatchReducer({ type: "qty", value: arr });
    const update = state.productArray.findIndex((e) => e.id === id);
    const arry = [...state.productArray];
    arry[update].qty = qty;

    arry[update].total = state?.containerTypeSelect == "pod" ? Number(arry[update].cost) : Number(qty) * Number(arry[update].cost);
    setProductArray(arry);
    if (
      item?.is_packaging_material === 1 ||
      item?.is_packaging_material === "1"
    ) {
      const arr = [...state.packageMaterial];

      const index = arr.findIndex((e) => e?.id === id);
      if (index !== -1) {
        arr[index].qty = qty;
        dispatchReducer({ type: "packageMaterial", value: arr });
      }
    }
  };

  const handleFind = (e, id) => {
    if (e.id === id) {
      return e.qty;
    }
  };

  const handleArrayUpdate = (orderData, reduxState) => {
    const arr = [...reduxState?.productArray];

    let podCount = "";
    let subTotal = 0;
    let count = arr.find((e) => e?.type === "plan");
    if (count?.qty) {
      podCount = +count?.qty;
    }
    let containerCheck = arr.some((e) => e.id === "Container") || state.containerTypeSelect === "container";
    if (containerCheck) {
      const couRemove = [...reduxState?.coupon_id];
      const filteredCoupon = couRemove.filter((e) => e.isDeletable);

      // dispatchReducer({ type: "coupon", value: filteredCoupon , check:"7"});
    }

    for (let items of orderData) {
      let qtys = reduxState?.quantity?.find((e) => handleFind(e, items.item.id))
        ?.qty;
      if (items.item?.type === "plan" && qtys) {
        podCount = qtys;
      }
      const currencyIndex = items?.price?.findIndex(
        (e) => e?.item_price.currency_code === (reduxState?.currencyCode || "GBP")
      );
      let update = arr.some((e) => e.id === items.item.id);
      let updateIndex = arr.findIndex((e) => e.id === items.item.id);

      if (!update) {
        const data = productArrayNewData(
          items,
          qtys,
          currencyIndex,
          quantity,
          reduxState
        );

        if (
          +data.competitor_manual_discount <= 0 &&
          data.competitor_manual_discount !== "" &&
          state.competitorPrice.check
        ) {
          // console.log("hello-123", data?.competitorPrice);
          const dataPrice = {
            price: 0,
            check: false,
          };
          const dataPram = {
            collection_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_collection_date : state?.collectionRetrun?.collection_date || "",
            pod: data.qty || "",
            return_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_return_date :
              state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_date
                : "",
            return_postcode:
              state.collectionRetrun?.return_postcode &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_postcode
                : "",
            is_student: state.collectionRetrun?.is_student || 0,
            is_student_return: state.collectionRetrun?.is_student_return || 0,
            competitor_price: 0,
            isCompetitor: false,
          };
          dispatchReducer({ type: "competitorPrice", value: dataPrice });
          handleBilling(
            state?.params?.period === "week"
              ? "1w"
              : state?.params?.unit
                ? state?.params?.unit
                : 1,
            dataPram
          );
        }

        if (
          data.pricing_model === "stairstep" ||
          data?.pricing_model === "tiered"
        ) {
          data.qty = podCount || 1;
          data.total = data.cost;
        }
        if (items?.item?.metadata?.is_packaging_material === "1") {
          data.is_packaging_material =
            items?.item?.metadata?.is_packaging_material;
        }
        arr.push(data);
        subTotal = subTotal + Number(data.total === "N/A" ? 0 : data.total);
      } else if (update) {
        if (arr[updateIndex].type === "plan") {
          podCount =
            arr[updateIndex].type === "plan"
              ? Number(arr[updateIndex].qty)
              : podCount;
        }
        if (arr[updateIndex]?.is_packaging_material === "1") {
          arr[updateIndex].is_packaging_material = "1";
        }
        productArrayUpdated(
          arr,
          updateIndex,
          items,
          currencyIndex,
          reduxState,
          podCount
        );

        if (
          +arr[updateIndex].competitor_manual_discount <= 0 &&
          arr[updateIndex].competitor_manual_discount !== "" &&
          state.competitorPrice.check
        ) {
          const dataPrice = {
            price: 0,
            check: false,
          };
          const data = {
            collection_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_collection_date : state?.collectionRetrun?.collection_date || "",
            pod:
              (state?.productArray.length > 0 &&
                state?.productArray.find((e) => e.type === "plan")?.qty) ||
              "",
            return_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_return_date :
              state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_date
                : "",
            return_postcode:
              state.collectionRetrun?.return_postcode &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_postcode
                : "",
            is_student: state.collectionRetrun?.is_student || 0,
            is_student_return: state.collectionRetrun?.is_student_return || 0,
            competitor_price: 0,
            isCompetitor: false,
          };
          dispatchReducer({ type: "competitorPrice", value: dataPrice });
          handleBilling(
            state?.params?.period === "week"
              ? "1w"
              : state?.params?.unit
                ? state?.params?.unit
                : 1,
            data
          );
        }
      }
    }

    setProductArray(arr);

    const arrPlan = arr.filter((e) => e.type === "plan");
    const arrAddon = arr.filter((e) => e.type === "addon");
    let arrCharge = arr.filter((e) => e.type === "charge");
    // if (arrPlan.some((e) => e?.type === "plan")) {
    //   const count = arrPlan.find((e) => e?.type === "plan")?.qty;
    //   const index = arrCharge.findIndex(
    //     (e) => e.pricing_model === "stairstep" || e.pricing_model === "tiered"
    //   );
    //   if (index > -1) {
    //     arrCharge[index].qty = +count;
    //   }
    // }
    const planAddon = arrPlan.concat(arrAddon);
    let sortOrder = planAddon.concat(arrCharge);
    const arrProductId = [];
    sortOrder.forEach((e) => {
      arrProductId.push(e.id);
    });
    if (state?.packageMaterial?.length > 0) {
      const data = handleUpdateQtyPackaging(sortOrder, state?.packageMaterial);
      sortOrder = data;
      dispatchReducer({
        type: "packageMaterialAdd",
        value: false,
      });
    }
    setParamData({ ...paramData, products_id: arrProductId });
    const week = WeekCounter(state?.collectionRetrun?.collection_date, state?.collectionRetrun?.return_date)
    const pod = state?.productArray.find((e) => e.type === "plan");
    const filteredArrProduct = sortOrder.filter((e) => e.id !== 'Adhoc-Charges');
    dispatchReducer({
      type: "productArray",
      productArray: +week > 12 && pod?.id === "First-Class" ? filteredArrProduct : sortOrder,
      check: "8",
    });
  };

  useEffect(() => {
    if (orderData && orderData.length > 0) {
      handleArrayUpdate(orderData, state);

      dispatchReducer({ type: "vat", value: orderDataTwo?.tax_rate });
    } else if (
      orderData &&
      orderData.length <= 0 &&
      !location?.search.includes("quote")
    ) {
      dispatchReducer({ type: "productArray", productArray: [], check: "9" });
    } else if (
      orderData &&
      orderData.length <= 0 &&
      location?.search.includes("quote") &&
      state.isQuote
    ) {
      dispatchReducer({ type: "productArray", productArray: [], check: "9" });
    }
  }, [
    orderData?.length,
    quantity,
    totalData?.discount,
    state?.manualDiscount,
    state?.productArray?.length,
  ]);
  useEffect(() => {
    dispatch(fetchActiveCountry());
  }, [dispatch]);

  useEffect(() => {
    if (cardDetail && cardDetail.length && cardDetail[0].contact) {
      const contryCurrency = detailData.filter(
        (element) =>
          element.id ===
          Number(
            cardDetail &&
            cardDetail.length &&
            cardDetail[0] &&
            cardDetail[0]?.contact &&
            cardDetail[0]?.contact?.franchise &&
            cardDetail[0]?.contact?.franchise.country_id
          )
      );
      if (contryCurrency.length) {
        const currencyCode = currencyApi.filter(
          (element) => element.name === contryCurrency[0].currency
        );
        dispatchReducer({ type: "currency", value: currencyCode[0]?.cc });
      }
    }
  }, [cardDetail, detailData]);

  const handleDeleteDiscount = () => {
    setTotalData({ ...totalData, discountValue: "", discount: [] });
    const arr = [...state?.coupon_id];
    const filteredCoupon = arr.filter(
      (e) => e.check === "billing" && !e.isDeletable
    );
    dispatchReducer({ type: "setDiscountValue", value: 0 });
    dispatchReducer({ type: "manualDiscount", manualDiscount: {} });
    dispatchReducer({ type: "coupon", value: filteredCoupon, check: "8" });
  };

  const handleUpdateSubTotal = () => {
    const initialValue = 0;
    const sumWithInitial = state.productArray.reduce(
      (previousValue, currentValue) =>
        previousValue +
        Number(currentValue.total === "N/A" ? 0 : currentValue.total),
      initialValue
    );
    setTotalData({
      ...totalData,
      subTotal: sumWithInitial,
    });

    dispatchReducer({ type: "subTotal", value: sumWithInitial });
  };
  // useEffect(() => {
  //   if (state?.productArray && state?.productArray.length) {
  //     handleUpdateSubTotal();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state?.productArray]);
  const debouncedUpdateSubTotal = useCallback(debounce(() => {
    handleUpdateSubTotal();
  }, 300), [handleUpdateSubTotal]);

  useEffect(() => {
    if (state?.productArray && state?.productArray.length) {
      debouncedUpdateSubTotal();
    }
    // Clean up the debounce effect on unmount
    return () => {
      debouncedUpdateSubTotal.cancel();
    };
  }, [state?.productArray, debouncedUpdateSubTotal]);



  const handleChecked = (e) => {
    const data = {
      collection_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_collection_date : state?.collectionRetrun?.collection_date || "",
      pod:
        (state?.productArray.length > 0 &&
          state?.productArray.find((e) => e.type === "plan")?.qty) ||
        "",
      return_date: state.containerTypeSelect === "container" ? state?.collectionRetrun?.container_return_date :
        state.collectionRetrun?.return_date &&
          state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_date
          : "",
      return_postcode:
        state.collectionRetrun?.return_postcode &&
          state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_postcode
          : "",
      is_student: state.collectionRetrun?.is_student || 0,
      is_student_return: state.collectionRetrun?.is_student_return || 0,
      packaging_material: e ? "1" : "0",
      competitor_price: state.competitorPrice?.check
        ? state.competitorPrice?.price
        : 0,
      isCompetitor: state.competitorPrice?.check,
      container_size: state.containerListSelect,
      site_id: state?.siteSelect?.id,
      is_plan_replaced: state?.is_plan_replaced
      // container_plan:state.containerPlanListSelect
    };
    // if(!e){

    const productId = !e ? paramData?.products_id.filter((e) => e !== "ShippingFee") : paramData?.products_id
    // }
    dispatch(
      fetchOrderSummary(
        state?.deletedProduct,
        "12",
        paramData.unit,
        paramData.period,
        productId,
        cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id
          ? cardDetail[0].contact?.franchise_id
          : "",
        cardDetail && cardDetail.length && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : "",
        "",
        "",
        "",
        "",
        data
      )
    );
    dispatchReducer({
      type: "isShip",
      value: e ? "1" : "0",
    });
    setShipLoad(true);
  };
  useEffect(() => {
    if (
      orderData &&
      orderData?.length &&
      orderData?.some(
        (e) => e?.item?.metadata?.is_packaging_material === "1"
      ) &&
      !shipLoad &&
      typeCheck !== "sendQuote"
    ) {
      handleChecked(true);
    }
  }, [state?.productArray?.length]);

  // eslint-disable-next-line no-unused-vars
  const variantProductTotal = useMemo(() => {
    let vatProduct = 0;
    if (state?.productArray?.find((ft) => ft.vatPrice)?.vatPrice) {
      // eslint-disable-next-line no-unused-expressions
      state?.productArray.map((item) => {
        if (item?.vatPrice) {
          vatProduct += Number(item?.total);
        }
        return item;
      });
      if (vatProduct) {
        dispatchReducer({
          type: "vatTotal",
          value: ((Number(vatProduct) / 1.2) * Number(state?.vat)) / 100,
        });
        return vatProduct;
      } else return 0;
    } else {
      return 0;
    }
  }, [state?.productArray]);

  const getTotalValueEasy = useMemo(() => {
    if (!state.productArray || !state.coupon_id) return;
    const obj = {
      subTotal: 0,
      discount: 0,
      applyCodeDiscount: 0,
      podDiscount: 0,
    };
    // let counter = WeekCounter(
    //   state.collectionRetrun?.collection_date,
    //   state.collectionRetrun?.return_date
    // );
    // let subTotal = 0;

    let podTotal = +state?.productArray?.find((e) => e.type === "plan")?.total;
    let podTotalValue = +state?.productArray?.find((e) => e.type === "plan")
      ?.total;
    if (!podTotal) return;
    const billingDiscounts = state.coupon_id.filter(
      (e) => e.check === "billing"
    );
    const sortedBilingDiscount = rearrangeArray(billingDiscounts);
    if (sortedBilingDiscount.length) {
      for (const bl of sortedBilingDiscount) {
        if (bl.type === "percent") {
          obj.discount = obj.discount + podTotal * (bl.value / 100);
          obj.podDiscount = obj.podDiscount + podTotal * (bl.value / 100);
          podTotal = podTotal - podTotal * (bl.value / 100);
        } else if (bl.type === "fixed" || bl.type === "fixed_amount") {
          // TODO Check fixed

          obj.discount =
            podTotalValue > bl.value ? obj.discount + bl.value : podTotalValue;
          obj.podDiscount =
            podTotalValue > bl.value
              ? obj.podDiscount + bl.value
              : podTotalValue;
          podTotal = podTotalValue > bl.value ? podTotal - bl.value : 0;
        }
      }
    }

    obj.subTotal += podTotal;

    const productTotalWithoutPlan = +state?.productArray?.reduce(
      (total, val) => {
        if (val.type !== "plan") return (total += !val.total ? +val.qty * val.cost : +val.total);
        return (total += 0);
      },
      0
    );
    obj.subTotal += productTotalWithoutPlan;
    const discountsWithoutBilling = state.coupon_id.filter(
      (e) => e.check !== "billing"
    );
    const newSortedArray = rearrangeArray(discountsWithoutBilling);
    if (newSortedArray.length) {
      for (const bl of newSortedArray) {
        if (bl.type === "fixed" || bl.type === "fixed_amount") {
          // TODO Check fixed
          obj.discount = obj.discount + bl.value;
          obj.applyCodeDiscount = obj.applyCodeDiscount + bl.value;
          obj.subTotal = obj.subTotal - bl.value;
        } else if (bl.type === "percent") {
          obj.discount = obj.discount + obj.subTotal * (bl.value / 100);
          obj.applyCodeDiscount =
            obj.applyCodeDiscount + obj.subTotal * (bl.value / 100);
          obj.subTotal = obj.subTotal - obj.subTotal * (bl.value / 100);
        }
      }
    }
    return obj;
  }, [state]);
  //need to delete in future not in use right now
  const getSubTotalValue = useMemo(() => {
    const totalPodCost = state?.productArray?.find((e) => e.type === "plan");
    const billingCoupon = state?.coupon_id?.filter(
      (ft) => ft.check === "billing"
    );
    const data = {
      subTotal: state?.subTotal,
      discount: 0,
      vatProduct: 0,
      vatTotal: 0,
      podTotal:
        totalPodCost?.total && billingCoupon.length > 0
          ? Number(totalPodCost?.total)
          : 0,
      podDiscount: "",
    };

    if (state?.coupon_id?.length) {
      const mannualCalculation = state?.coupon_id.find(
        (ft) => ft.name === "manual"
      );
      const coupons = state?.coupon_id.filter(
        (ft) => ft.name !== "manual" && ft.check !== "billing"
      );
      if (mannualCalculation) coupons.unshift(mannualCalculation);
      // coupons.filter((ft) => ft.check !== "billing");

      if (state?.coupon_id?.length && billingCoupon) {
        billingCoupon.map((offer) => {
          if (offer?.type === "percent") {
            data["discount"] =
              data["discount"] + (data["podTotal"] * offer.value) / 100;
            data["podTotal"] =
              data["podTotal"] - (data["podTotal"] * offer.value) / 100;
            data["podDiscount"] = data["podTotal"] - data["discount"];
          } else if (
            offer?.type === "fixed" ||
            offer?.type === "fixed_amount"
          ) {
            data["podTotal"] = Number(data["podTotal"]) - Number(offer.value);
            data["discount"] = data["discount"] + Number(offer.value);
          }
          return offer;
        });
      }
      // else if (coupons.length) {
      // eslint-disable-next-line no-unused-expressions
      coupons.map((offer) => {
        if (offer?.type === "percent") {
          data["discount"] =
            data["discount"] + (data["subTotal"] * offer.value) / 100;
          data["subTotal"] =
            data["subTotal"] - (data["subTotal"] * offer.value) / 100;
        } else if (offer?.type === "fixed" || offer?.type === "fixed_amount") {
          data["subTotal"] = Number(data["subTotal"]) - Number(offer.value);
          data["discount"] = data["discount"] + Number(offer.value);
        }
        return offer;
      });
      // }
      return data;
    } else {
      dispatchReducer({ type: "total", value: state?.subTotal });
    }

    return data || 0;
  }, [state?.coupon_id?.length, state?.subTotal, state?.productArray?.length]);
  useEffect(() => {
    if (getTotalValueEasy?.discount) {

      dispatchReducer({
        type: "total",
        value:
          Number(getTotalValueEasy?.subTotal) > 0
            ? Number(getTotalValueEasy.subTotal)
            : // : Number(getSubTotalValue.subTotal) > 0
            // ? Number(getSubTotalValue.subTotal)
            // -
            // Number(getSubTotalValue?.podTotal)
            0,
      });
      dispatchReducer({
        type: "totalDisVal",
        discountValue: getTotalValueEasy?.discount,
        applyCodeDiscount: getTotalValueEasy?.applyCodeDiscount,
      });
    }
  }, [
    getTotalValueEasy?.discount,
    getSubTotalValue?.vatProduct,
    getTotalValueEasy?.subTotal,
    getSubTotalValue,
  ]);

  const handleAddon = (e, coverData, addonId, f) => {
    const dd = Object.entries(coverData);
    let price = "";
    // eslint-disable-next-line array-callback-return
    dd.find(([keys, value]) => {
      if (keys === e) {
        price = value;
        return value;
      }
    });
    let arr = [...state?.productArray];
    const index = arr.findIndex((e) => e?.id === addonId);
    if (index > -1) {
      arr[index].cover = e;
      arr[index].total = price;
      dispatchReducer({
        type: "cover",
        value: { cover: e, price: price },
      });
    }
    dispatchReducer({
      type: "productArray",
      productArray: arr,
      check: "10",
    });
  };
  useEffect(() => {
    if (
      location?.search.includes("quote") &&
      !check &&
      state?.productArray.length > 0
    ) {
      const addonData = state?.productArray.find((e) => e.type === "addon");
      if (addonData) {
        handleAddon(addonData?.cover, addonData?.cover_list, addonData?.id);
      }
      setCheck(true);
    }
  }, [check]);

  return (
    <>
      <Table responsive className="summerytable">
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th className="productsta" colSpan={2}>
              Product
            </th>
            <th className="cost">Cost</th>
            <th>Qty</th>
            <th className={state.collectionRetrun?.return_start_time_slot ? `w-25` : ""}>
              Total&nbsp;{state.collectionRetrun?.collection_date &&
                state.collectionRetrun?.return_date && state.collectionRetrun?.return_start_time_slot
                ? `(${WeekCounter(
                  state.collectionRetrun?.collection_date,
                  state.collectionRetrun?.return_date
                )} weeks)`
                : ""}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading && state.productArray && state.productArray.length ? (
            state.productArray.map((data, index) => {
              const totalindex = state?.productArray.findIndex(
                (e) => e.id === data.id
              );
              const dataAdhoc = data.id === state?.adHoc?.id;
              return (
                <tr key={index}>
                  {/* <td className="w-25">
                    {data?.id} <br />
                    <span></span>
                  </td> */}
                  <td colSpan={2}>
                    {data?.name}
                    {dataAdhoc ? (
                      <div className="text-muted">
                        ({state?.adhocChargeName})
                      </div>
                    ) : (
                      ""
                    )}
                    {data?.extra_pod_price ? (
                      <div className="text-muted">
                        (extra&nbsp;{+state?.params?.unit - 2}
                        &nbsp;months&nbsp;price:&nbsp;{data?.extra_pod_price})
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    className=" d-flex justify-content-between"
                    style={{ width: "70px" }}
                  >
                    {state?.productArray[totalindex]?.id === "Howdens" ? (
                      <>-</>
                    ) : (
                      <>
                        {(state &&
                          state?.productArray &&
                          state?.productArray.length &&
                          state?.productArray[totalindex]?.productPrice !==
                          "N/A") ||
                          (state &&
                            state?.productArray &&
                            state?.productArray.length &&
                            state?.productArray[totalindex]?.id !== "Howdens")
                          ? `${state?.currencyCode
                            ? convertGBPtoPoundSymbol(state.currencyCode)
                            : ""
                          }
                         `
                          : ""}

                        <span style={{ float: "right" }}>
                          {(state &&
                            state?.productArray &&
                            state?.productArray.length &&
                            state?.productArray[totalindex]?.productPrice !==
                            "N/A") ||
                            (state &&
                              state?.productArray &&
                              state?.productArray.length &&
                              state?.productArray[totalindex]?.id !== "Howdens")
                            ? `
                       ${Number(
                              state?.productArray[totalindex]?.productPrice
                            ).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}  `
                            : "0"}
                        </span>
                      </>
                    )}
                  </td>
                  <td style={{ verticalAlign: "baseline" }}>
                    {data.type === "addon" && (data.id === "Container-Protect" || data.id === "Store-Protect" || data.id === "Insurance-Cover") ? (
                      <Select
                        name="address"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control marginCustom11  `}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        value={data.cover}
                        onChange={(e) =>
                          handleAddon(
                            e.target.value,
                            data?.cover_list,
                            data?.id,
                            e
                          )
                        }
                      >
                        {data?.cover_list &&
                          Object.entries(data?.cover_list).map(
                            ([keys, values], index) => {
                              const number = keys ? Number(keys) : "";
                              return (
                                <MenuItem value={keys}>
                                  {state?.currencyCode
                                    ? convertGBPtoPoundSymbol(
                                      state.currencyCode
                                    )
                                    : ""}
                                  {number.toLocaleString("en-IN")}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    ) : (
                      <InputGroup className="qty-group">
                        <FormControl
                          type="number"
                          placeholder="Qty"
                          aria-label="Qty"
                          aria-describedby="basic-addon1"
                          // max={"10"}
                          maxLength="12"
                          step="1"
                          value={data?.qty || ""}
                          onChange={(e) =>
                            handleQty(
                              e.target.value > 0
                                ? e.target.value
                                : e.target.value === ""
                                  ? ""
                                  : 1,
                              index,
                              data?.id,
                              data
                            )
                          }
                          disabled={
                            // data?.type === "addon" ||
                            data.pricing_model === "stairstep" ||
                            data?.pricing_model === "tiered"
                          }
                          style={{ paddingRight: "10px" }}
                        />
                      </InputGroup>
                    )}
                  </td>
                  <td className="d-flex justify-content-between">
                    {state &&
                      state?.productArray &&
                      state?.productArray.length &&
                      state?.productArray[totalindex]?.total !== "N/A"
                      ? state?.currencyCode
                        ? convertGBPtoPoundSymbol(state.currencyCode)
                        : ""
                      : ""}

                    <span style={{ float: "right" }}>

                      {state &&
                        state?.productArray &&
                        state?.productArray.length &&
                        (state?.productArray[totalindex]?.type === "plan" ||
                          state?.productArray[totalindex]?.type === "addon") && state.collectionRetrun?.collection_date &&
                        state.collectionRetrun?.return_date && state.collectionRetrun?.return_start_time_slot &&
                        state?.productArray[totalindex]?.total !== "N/A"
                        ? <>
                          {Number(Number(
                            state?.productArray[totalindex]?.total
                          ) *
                            (state.collectionRetrun?.collection_date &&
                              state.collectionRetrun?.return_date
                              ? WeekCounter(
                                state.collectionRetrun?.collection_date,
                                state.collectionRetrun?.return_date
                              )
                              : 1))?.toFixed(2)}
                        </>
                        : state &&
                          state?.productArray &&
                          state?.productArray.length && state?.productArray[totalindex]?.total !== "N/A"
                          ? Number(
                            state?.productArray[totalindex]?.total
                          ).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                          : "N/A"}{" "}
                    </span>
                  </td>
                  <td className="cart-icon">
                    {typeCheck === "sendQuote" ||
                      // data.pricing_model === "stairstep" ||
                      // data?.pricing_model === "tiered" ||
                      data?.type === "plan" ||
                      data?.id === "Toll-Charges-with-VAT" || data?.id == "ShippingFee" ? (
                      ""
                    ) : (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ">
                        <SVG
                          title="Delete"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                          onClick={() => handleDelete(data?.id, index, (data.pricing_model === "stairstep" ||
                            data?.pricing_model === "tiered") || state.containerTypeSelect === "container" ? "delete" : "")}
                        />
                      </span>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <div>
                    {loading ? (
                      <span className="ml-2 mr-2 spinner spinner"></span>
                    ) : (
                      <p>No&nbsp;Record&nbsp;Found</p>
                    )}
                  </div>
                </td>
              </tr>
            </>
          )}
          {(cardDetail && cardDetail.length && orderData && orderData.length) ||
            state?.productArray.length > 0 ? (
            <>
              <tr>
                <td colSpan="2">
                  {" "}
                  <div>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="enabled_for_checkout"
                        checked={state?.isShip === "1" ? true : false}
                        onChange={(e) => handleChecked(e.target.checked)}
                        disabled={
                          orderData && orderData?.length > 0 &&
                            orderData?.some(
                              (e) =>
                                e?.item?.metadata?.is_packaging_material === "1"
                            )
                            ? false
                            : true
                        }
                      />
                      <span className="mr-2"></span>
                      Ship packaging materials
                    </label>
                  </div>
                </td>

                {/* <td></td> */}
                <td className="text-right" colSpan={2}>
                  {" "}
                  {/* {!state.collectionRetrun?.return_start_time_slot ? ( */}
                  <strong>
                    Subtotal&nbsp;(inclusive of VAT)

                  </strong>
                  {/* ) : (
                    ""
                  )} */}
                </td>
                <td>
                  {/* {!state?.collectionRetrun?.return_start_time_slot ? ( */}
                  <div className="d-flex justify-content-between">
                    {totalData && totalData?.subTotal && state?.currencyCode
                      ? convertGBPtoPoundSymbol(state.currencyCode)
                      : ""}

                    <span style={{ float: "right" }}>
                      {state.collectionRetrun?.collection_date &&
                        state.collectionRetrun?.return_date &&
                        state.collectionRetrun?.return_start_time_slot
                        ? handleFinalPriceCalculation(state).toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )
                        : totalData && totalData?.subTotal
                          ? Number(totalData?.subTotal)
                            // -
                            // Number(state.vatTotal > 0 ? state?.vatTotal : 0)
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                    </span>
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                </td>
                {/* <td></td> */}
              </tr>
              {state.collectionRetrun?.collection_date &&
                state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot &&
                !state.productArray.some((e) => e.id === "Howdens") ? (
                <>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top text-right">
                      {" "}
                      <strong>Total&nbsp;Duration</strong>
                    </td>

                    <td className="d-flex justify-content-end" >
                      {WeekCounter(
                        state.collectionRetrun?.collection_date,
                        state.collectionRetrun?.return_date
                      ) + " weeks" || ""}
                    </td>

                  </tr>
                </>
              ) : (
                ""
              )}
              {state.collectionRetrun?.collection_date &&
                state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot &&
                !state.productArray.some((e) => e.id === "Howdens") ? (
                <tr>
                  {state?.adhocChargeName && state?.adhocGbp ? (
                    <td
                      className="bord-top"
                      colSpan={state?.adhocChargeName ? 2 : 0}
                    >
                      {/* Adhoc Charge - {state?.adhocChargeName} */}
                    </td>
                  ) : (
                    <td className="bord-top"></td>
                  )}
                  {state?.adhocChargeName && state?.adhocGbp ? (
                    ""
                  ) : (
                    <td className="bord-top"></td>
                  )}
                  <td className="bord-top"></td>
                  <td className="bord-top text-right">
                    {" "}
                    <strong> Total Price</strong>
                  </td>
                  <td className="bord-top d-flex justify-content-between">
                    {state.collectionRetrun?.collection_date &&
                      state.collectionRetrun?.return_date &&
                      state.collectionRetrun?.return_start_time_slot ? (
                      <>
                        {state.totalData && state.totalData.discountValue
                          ? ` ${totalData &&
                            totalData?.subTotal &&
                            state?.currencyCode
                            ? convertGBPtoPoundSymbol(state.currencyCode)
                            : ""
                          } `
                          : `${totalData &&
                            totalData?.subTotal &&
                            state?.currencyCode
                            ? convertGBPtoPoundSymbol(state.currencyCode)
                            : ""
                          }`}

                        <span style={{ float: "right" }}>
                          {" "}
                          {Number(state?.total)?.toFixed(2)
                            ? handleFinalPriceCalculation(state).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )
                            : 0}{" "}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td className="bord-top"></td>
                <td className="bord-top"></td>
                {/* <td className="bord-top"></td>  */}
                <td className="bord-top text-right" colSpan={2}>
                  {" "}
                  <strong>Discount on Storage</strong>
                </td>
                <td className="bord-top d-flex justify-content-between">
                  {state && state.currencyCode
                    ? convertGBPtoPoundSymbol(state.currencyCode)
                    : // eslint-disable-next-line no-useless-concat
                    "" + " "}

                  <span style={{ float: "right" }}>
                    {" "}
                    {state.collectionRetrun?.collection_date &&
                      state.collectionRetrun?.return_date && state.collectionRetrun?.return_start_time_slot ? (
                      handleFinalPrice(state)?.podDiscount?.toFixed(2) || "-"
                    ) : (
                      <>{Number(getTotalValueEasy?.podDiscount)?.toFixed(2)} </>
                    )}
                  </span>
                </td>
                {state?.coupon_id.filter((e) => e.check !== "billing").length >
                  0 && state.manualDiscount?.discountType ? (
                  <td className="bord-top cart-icon">
                    <span
                      className="svg-icon svg-icon-sm svg-icon-primary"
                      onClick={() => handleDeleteDiscount()}
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              {state.totalData?.discountValue ? (
                <tr>
                  <td className="bord-top"></td>
                  <td className="bord-top"></td>
                  {/* <td className="bord-top"></td> */}
                  <td className="bord-top text-right" colSpan={2}>
                    {" "}
                    <strong>Total Discount</strong>
                  </td>
                  <td className="bord-top d-flex justify-content-between">
                    {state && state.currencyCode
                      ? convertGBPtoPoundSymbol(state.currencyCode)
                      : // eslint-disable-next-line no-useless-concat
                      "" + " "}
                    {state.collectionRetrun?.collection_date &&
                      state.collectionRetrun?.return_date &&
                      state.collectionRetrun?.return_start_time_slot &&
                      !state.productArray.some((e) => e.id === "Howdens") ? (
                      <>
                        {" "}
                        <span style={{ float: "right" }}>
                          {" "}
                          {handleFinalPrice(state)?.discountedPrice?.toFixed(2)}
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ float: "right" }}>
                          {" "}
                          {Number(getTotalValueEasy?.discount)?.toFixed(2)}{" "}
                        </span>
                      </>
                    )}
                  </td>

                  {state?.coupon_id.filter((e) => e.isDeletable).length > 0 ? (
                    <td className="bord-top cart-icon">
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary"
                        onClick={() => handleDeleteDiscount()}
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ) : null}
              {!state.collectionRetrun?.return_start_time_slot ||
                state.productArray.some((e) => e.id === "Howdens") ? (
                <tr>
                  {state?.adhocChargeName && state?.adhocGbp ? (
                    <td
                      className="bord-top"
                      colSpan={state?.adhocChargeName ? 2 : 0}
                    >
                      {/* Adhoc Charge - {state?.adhocChargeName} */}
                    </td>
                  ) : (
                    <td className="bord-top"></td>
                  )}
                  {state?.adhocChargeName && state?.adhocGbp ? (
                    ""
                  ) : (
                    <td className="bord-top"></td>
                  )}
                  <td className="bord-top"></td>
                  <td className="bord-top text-right">
                    {" "}
                    <strong> Total</strong>
                  </td>
                  <td className="bord-top d-flex justify-content-between">
                    <>
                      {state.totalData && state.totalData.discountValue
                        ? ` ${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        } `
                        : `${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        }`}
                      <span style={{ float: "right" }}>
                        {" "}
                        {Number(state?.total)?.toFixed(2)
                          ? Number(+state?.total)?.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                          : 0}
                      </span>
                    </>
                  </td>
                  {/* <td>
                  Test - {getTotalValueEasy?.subTotal}&nbsp;
                  {getTotalValueEasy?.discount}
                </td> */}
                </tr>
              ) : (
                ""
              )}
              {state.collectionRetrun?.collection_date &&
                state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot &&
                !state.productArray.some((e) => e.id === "Howdens") ? (
                <>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top text-right">
                      {" "}
                      <strong> Final Price</strong>
                    </td>
                    <td
                      className="bord-top d-flex justify-content-between"
                      colSpan="2"
                    >
                      {state.totalData && state.totalData.discountValue
                        ? ` ${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        } `
                        : `${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        }`}

                      <span style={{ float: "right" }}>
                        {" "}
                        {handleFinalPrice(state)?.total}{" "}
                      </span>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
              {state.collectionRetrun?.collection_date && (state?.containerTypeSelect != "pod" || (state?.containerTypeSelect == "pod" && state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e) => e.type === "plan" && e?.id !== "Economy-POD"))) &&
                // Convert string date to Date object
                new Date(state.collectionRetrun?.collection_date) >
                new Date(Date.now() + 48 * 60 * 60 * 1000) ? (
                <>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top text-right">
                      {" "}
                      <strong>Due today</strong>
                    </td>
                    <td
                      className="bord-top d-flex justify-content-between"
                      colSpan="2"
                    >
                      {state.totalData && state.totalData.discountValue
                        ? ` ${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        } `
                        : `${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        }`}

                      <span style={{ float: "right" }}>
                        {" "}
                        {state.collectionRetrun?.collection_date &&
                          state.collectionRetrun?.return_date &&
                          state.collectionRetrun?.return_start_time_slot &&
                          !state.productArray.some((e) => e.id === "Howdens") ? (handleFinalPrice(state)?.total.toFixed(2) * 0.1).toFixed(2) :
                          state?.total && (+state?.total.toFixed(2) * 0.1).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top"></td>
                    <td className="bord-top text-right">
                      {" "}
                      <strong>Due on {moment(new Date(new Date(state.collectionRetrun?.collection_date).getTime() - 48 * 60 * 60 * 1000)).format("DD-MM-yyyy")}</strong>
                    </td>
                    <td
                      className="bord-top d-flex justify-content-between"
                      colSpan="2"
                    >
                      {state.totalData && state.totalData.discountValue
                        ? ` ${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        } `
                        : `${totalData &&
                          totalData?.subTotal &&
                          state?.currencyCode
                          ? convertGBPtoPoundSymbol(state.currencyCode)
                          : ""
                        }`}

                      <span style={{ float: "right" }}>
                        {" "}
                        {state.collectionRetrun?.collection_date &&
                          state.collectionRetrun?.return_date &&
                          state.collectionRetrun?.return_start_time_slot &&
                          !state.productArray.some((e) => e.id === "Howdens") ? (handleFinalPrice(state)?.total.toFixed(2) * 0.9).toFixed(2) :
                          state?.total && (+state?.total.toFixed(2) * 0.9).toFixed(2)}{" "}
                      </span>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
              {state?.params?.period !== "week" && state?.containerTypeSelect !== "container" && (
                <CompetitorPriceCheckBox
                  dispatchReducer={dispatchReducer}
                  state={state}
                  paramData={paramData}
                  handleBilling={handleBilling}
                  getTotalValueEasy={getTotalValueEasy}
                />
              )}

              {/* <CompetitorPriceCheckBox
                dispatchReducer={dispatchReducer}
                state={state}
                paramData={paramData}
                handleBilling={handleBilling}
                getTotalValueEasy={getTotalValueEasy}
              /> */}
            </>
          ) : null}
        </tbody>
      </Table>
    </>
  );
}
