import React, { useEffect, useState } from "react";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Table } from "react-bootstrap";
import moment from "moment";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  WeekCounter,
  // addTwoValue,
  convertGBPtoPoundSymbol,
} from "../../../utils/commonFunction";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { getCollectionExtraCharge } from "../_redux/order/orderCrud";

export const ReturnWizard = ({
  setDialogSave,
  extraData,
  loadingDialog,
  returnData,
  setReturnData,
  formik,
  orderDetail,
  setReturnChecks,
  returnChecks,
}) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({ apply_on: "" });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [collectionExtraPrice, setCollectionExtraPrice] = useState(null)

  const calculateFinalPrice = () => {
    const extraReturnPrice = returnChecks?.is_return_cost
      ? Number(returnData?.extra_return_price || 0)
      : 0;
    const extraReturnCharge = returnChecks?.is_return_charge
      ? Number(returnData?.return_extra_charge || 0)
      : 0;
    const podPrice = Number(returnData?.pod_price || 0);
    const tollCharge = returnChecks?.is_return_congestion_charge
      ? Number(returnData?.toll_charge || 0)
      : 0;
    const depot_price = Number(returnData?.depot_return_charge || 0);
    const extraMilesTotalPrice = returnChecks?.is_extra_miles
      ? Number(returnData?.extraMiles?.totalPrice || 0)
      : 0;
    const discount = Number(returnData?.discount || 0);

    const totalPrice =
      depot_price +
      extraReturnPrice +
      extraReturnCharge +
      podPrice +
      extraMilesTotalPrice +
      tollCharge -
      discount;
    return totalPrice;
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setSnack({
        call: "",
        type: "",
        message: "",
      });
    }, 3000); // 3 seconds delay

    return () => {
      clearTimeout(timer); // Cleanup function to clear the timer if the component is unmounted
    };
  }, []);
  useEffect(() => {
    const data = {
      order_id: orderDetail?.id || "",
      type: "return",
      return_date: formik?.values?.return_date || "",
    }
    getCollectionExtraCharge(data).then((res) => {
      setCollectionExtraPrice(res.data.data)
      formik.setFieldValue("return_extra_days_charge", res.data.data?.price)
      formik.setFieldValue("return_extra_days_type", res.data.data?.type)
    })
  }, [formik?.values?.return_date])
  return (
    <div>
      {" "}
      {/* <button
        type="button"
        className={`btn  
          "btn-primaryCus" 
         font-weight-bolder font-size-sm mr-3 `}
        onClick={(e) => setDialogSave({ type: false, returnWizard: false })}
      >
        <ArrowBackIcon />
      </button> */}
      {extraData && (
        <div className="px-10 mt-6">
          <div className="mb-4">
            <span>
              <strong>{intl.formatMessage({ id: "Date.selected" })}:</strong>{" "}
              {formik?.values?.return_date
                ? moment(formik?.values?.return_date).format("DD-MM-YYYY")
                : ""}{" "}
              {formik?.values?.return_start_time_slot
                ? "- " + formik?.values?.return_start_time_slot
                : ""}
              {formik?.values?.return_start_time_slot &&
                formik?.values?.return_end_time_slot &&
                formik?.values?.return_start_time_slot !==
                moment(
                  formik?.values?.return_date +
                  "T" +
                  formik?.values?.return_end_time_slot
                )
                  .subtract(1, "hours")
                  .format("HH:mm") &&
                "-" +
                moment(
                  formik?.values?.return_date +
                  "T" +
                  formik?.values?.return_end_time_slot
                )
                  .subtract(1, "hours")
                  .format("HH:mm")}
            </span>
          </div>
          <div className="mb-4">
            <span>
              <strong>Return Address :</strong>{" "}
              {formik?.values?.return_address_line !== ""
                ? formik?.values?.return_address_line
                : formik?.values?.collection_address_line}
            </span>
          </div>
          {/* <div className="mb-4">
            <span>
              <strong>Next Billing Date :</strong> 12-07-2023
            </span>
          </div> */}
          <div className="mb-4">
            {formik?.values?.return_date &&
              orderDetail?.subscription_data?.next_billing_at &&
              new Date(formik?.values?.return_date).getTime() >
              Number(orderDetail?.subscription_data?.next_billing_at) * 1000 ? (
              <span>
                <strong>Extra Duration : </strong>

                {WeekCounter(
                  orderDetail?.collection_date,
                  formik?.values?.return_date
                ) < 14
                  ? 13 -
                  WeekCounter(
                    orderDetail?.collection_date,
                    formik?.values?.return_date
                  ) +
                  " week's"
                  : "0"}
              </span>
            ) : (
              ""
            )}
          </div>
          {/* {intl.formatMessage({ id: "Do.you.changes.booking" })} */}
          <div className="mt-6 mb-4">
            <strong>Charges applied</strong>
            {loadingDialog ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <Table responsive className="summerytable customColor">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {returnData?.extra_return_price && (
                    <tr>
                      <td className="d-flex">
                        <div>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="is_cancel_charge"
                              checked={
                                returnChecks?.is_return_cost ? true : false
                              }
                              onChange={
                                (e) => {
                                  setReturnChecks({
                                    ...returnChecks,
                                    is_return_cost: e.target.checked ? 1 : 0,
                                  });
                                  if (
                                    !e.target.checked &&
                                    formData?.apply_on ===
                                    "discount_on_return_charge"
                                  ) {
                                    setFormData({
                                      ...formData,
                                      apply_on: "",
                                      discount: "",
                                    });
                                    setReturnData({
                                      ...returnData,
                                      discount: "",
                                    });
                                  }
                                }
                                // formik.setFieldValue(
                                //   "is_cancel_charge",
                                //   e.target.checked ? 1 : "0"
                                // )
                              }
                            />
                            <span className="mr-2"></span>
                          </label>
                        </div>
                        Return Cost:
                      </td>
                      <td>
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(+returnData.extra_return_price).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {/* {returnData?.return_extra_charge ? (
                    <tr>
                      <td className="d-flex">
                        <div>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="is_return_charge"
                              checked={
                                returnChecks?.is_return_charge ? true : false
                              }
                              onChange={
                                (e) => {
                                  setReturnChecks({
                                    ...returnChecks,
                                    is_return_charge: e.target.checked ? 1 : 0,
                                  });
                                  // if (
                                  //   !e.target.checked &&
                                  //   formData?.apply_on ===
                                  //     "discount_on_return_charge"
                                  // ) {
                                  //   setFormData({
                                  //     ...formData,
                                  //     apply_on: "",
                                  //     discount: "",
                                  //   });
                                  //   setReturnData({
                                  //     ...returnData,
                                  //     discount: "",
                                  //   });
                                  // }
                                }
                                // formik.setFieldValue(
                                //   "is_cancel_charge",
                                //   e.target.checked ? 1 : "0"
                                // )
                              }
                            />
                            <span className="mr-2"></span>
                          </label>
                        </div>
                        Return Extra Charge:
                      </td>
                      <td>
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(returnData.return_extra_charge).toFixed(2)}
                      </td>
                    </tr>
                  ):""} */}
                  {collectionExtraPrice?.price && formik?.values?.is_student_return != 1 ? (
                    <tr>
                      <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extra Return {collectionExtraPrice?.type}:
                      </td>
                      <td>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {collectionExtraPrice?.price}
                      </td>
                    </tr>
                  ) : ""}
                  {typeof returnData?.pod_price !== "undefined" && (
                    <tr>
                      <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Storage Cost:
                      </td>
                      <td>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {returnData.return_extra_charge
                          ? Number(
                            returnData.pod_price +
                            Number(returnData.return_extra_charge)
                          ).toFixed(2)
                          : Number(returnData.pod_price).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {typeof returnData?.toll_charge !== "undefined" && (
                    <tr>
                      <td className="d-flex">
                        {" "}
                        <div>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="is_cancel_charge"
                              checked={
                                returnChecks?.is_return_congestion_charge
                                  ? true
                                  : false
                              }
                              onChange={
                                (e) =>
                                  setReturnChecks({
                                    ...returnChecks,
                                    is_return_congestion_charge: e.target
                                      .checked
                                      ? 1
                                      : 0,
                                  })
                                // formik.setFieldValue(
                                //   "is_cancel_charge",
                                //   e.target.checked ? 1 : "0"
                                // )
                              }
                            />
                            <span className="mr-2"></span>
                          </label>
                        </div>
                        Return Congestion Charge:
                      </td>
                      <td>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(returnData.toll_charge).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {returnData?.extraMiles?.id && (
                    <tr>
                      <td className="d-flex">
                        {" "}
                        <div>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="is_cancel_charge"
                              checked={
                                returnChecks?.is_extra_miles ? true : false
                              }
                              onChange={
                                (e) => {
                                  setReturnChecks({
                                    ...returnChecks,
                                    is_extra_miles: e.target.checked ? 1 : 0,
                                  });
                                  if (
                                    !e.target.checked &&
                                    formData?.apply_on === "discount_on_miles"
                                  ) {
                                    setFormData({
                                      ...formData,
                                      apply_on: "",
                                      discount: "",
                                    });
                                    setReturnData({
                                      ...returnData,
                                      discount: "",
                                    });
                                  }
                                }
                                // formik.setFieldValue(
                                //   "is_cancel_charge",
                                //   e.target.checked ? 1 : "0"
                                // )
                              }
                            />
                            <span className="mr-2"></span>
                          </label>
                        </div>
                        Extra Miles Cost:
                      </td>
                      <td>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(returnData.extraMiles.totalPrice).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {typeof returnData?.depot_return_charge !== "undefined" && (
                    <tr>
                      <td>Depot Return:</td>
                      <td>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(returnData.depot_return_charge).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-right">
                      <strong>Total Price:</strong>
                    </td>
                    <td>
                      <strong>
                        {" "}
                        {orderDetail?.currency
                          ? convertGBPtoPoundSymbol(orderDetail?.currency)
                          : "-"}
                        &nbsp;
                        {Number(
                          ((returnChecks?.is_return_cost &&
                            +returnData?.extra_return_price) ||
                            0) +
                          ((returnChecks?.is_return_charge &&
                            +returnData?.return_extra_charge) ||
                            0) +
                          (+returnData?.pod_price || 0) +
                          ((returnChecks?.is_return_congestion_charge &&
                            +returnData?.toll_charge) ||
                            0) +
                          (+returnData?.depot_return_charge || 0) +
                          ((returnChecks?.is_extra_miles &&
                            +returnData?.extraMiles?.totalPrice) ||
                            0) + ((collectionExtraPrice?.price &&
                              +collectionExtraPrice?.price) ||
                              0)
                        ).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                  {returnData?.discount && (
                    <>
                      <tr>
                        <td className="text-right">
                          <strong>Discount:</strong>
                        </td>
                        <td>
                          <strong>
                            {" "}
                            {orderDetail?.currency
                              ? convertGBPtoPoundSymbol(orderDetail?.currency)
                              : "-"}
                            &nbsp;
                            {Number(returnData?.discount).toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right">
                          <strong>Final Price:</strong>
                        </td>
                        <td>
                          <strong>
                            {" "}
                            {orderDetail?.currency
                              ? convertGBPtoPoundSymbol(orderDetail?.currency)
                              : "-"}
                            &nbsp;
                            {calculateFinalPrice().toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            )}
          </div>
          <div className="row">
            <div className="col-8">
              <div style={{ width: "31%" }}>
                <FormControl fullWidth>
                  <label>Apply Discount On</label>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="form-control"
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    onChange={(e) => {
                      setSnack({
                        call: false,
                        type: "",
                        message: "",
                      });
                      if (e?.target?.value === "") {
                        setReturnData({ ...returnData, discount: "" });
                        setFormData({
                          ...formData,
                          apply_on: e.target.value,
                          discount: "",
                        });
                        return;
                      }
                      setFormData({
                        ...formData,
                        apply_on: e?.target?.value,
                      });
                    }}
                    // onBlur={handleBlur}
                    value={formData?.apply_on}
                  >
                    <MenuItem value="">Select Discount</MenuItem>
                    {returnChecks?.is_return_cost &&
                      returnData?.extra_return_price && (
                        <MenuItem value="discount_on_return_charge">
                          Apply discount on Return Charges
                        </MenuItem>
                      )}
                    {returnData.depot_return_charge && (
                      <MenuItem value="discount_on_depot_return_charge">
                        Apply discount on Depot Charges
                      </MenuItem>
                    )}
                    {returnChecks?.is_extra_miles &&
                      returnData?.extraMiles?.id && (
                        <MenuItem value="discount_on_miles">
                          Apply discount on Extra Miles
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-2  d-flex">
                <div className="form-inline">
                  {" "}
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder={"Amount"}
                    name="offerCode"
                    value={formData?.discount || ""}
                    maxLength="40"
                    onChange={(e) => {
                      setSnack({
                        call: false,
                        type: "",
                        message: "",
                      });
                      if (
                        formData?.apply_on === "discount_on_return_charge" &&
                        Number(+returnData?.extra_return_price || 0) >=
                        Number(e.target.value)
                      ) {
                        setFormData({ ...formData, discount: e.target.value });
                      }
                      if (
                        formData?.apply_on === "discount_on_miles" &&
                        Number(+returnData?.extraMiles?.totalPrice || 0) >=
                        Number(e.target.value)
                      ) {
                        setFormData({ ...formData, discount: e.target.value });
                      }
                      if (
                        formData?.apply_on ===
                        "discount_on_depot_return_charge" &&
                        Number(+returnData?.depot_return_charge || 0) >=
                        Number(e.target.value)
                      ) {
                        setFormData({ ...formData, discount: e.target.value });
                      }
                    }}
                  />
                  {/* {orderUIContext?.state?.coupon?.some(
                (e) => e.name === formData?.offerCode
              ) && (
                <span style={{ color: "red" }}>
                  {intl.formatMessage({ id: "Already.used" })}
                </span>
              )} */}
                </div>

                <div className="ml-3">
                  <button
                    type="button"
                    onClick={() => {
                      if (
                        !returnData?.extra_return_price &&
                        !returnData?.extraMiles?.id &&
                        !returnData.depot_return_charge
                      ) {
                        setSnack({
                          call: true,
                          type: "error",
                          message:
                            "Discount cannot be applied on storage cost.",
                        });
                        return;
                      }
                      // setFormData({ ...formData, discount: "" });
                      setReturnData({
                        ...returnData,
                        discount: returnData?.discount
                          ? ""
                          : formData?.discount,
                        apply_on: formData?.apply_on,
                      });
                    }}
                    disabled={!formData?.apply_on}
                    className="btn btn-primary font-weight-bolder font-size-sm mr-3 sapply-code "
                  // disabled={
                  //   formData?.offerCode === "" ||
                  //   orderUIContext?.state?.coupon?.some(
                  //     (e) => e.name === formData?.offerCode
                  //   )
                  // }
                  >
                    {returnData?.discount ? "Remove" : "Add Discount"}
                  </button>
                </div>
              </div>
              <div className="form-inline mt-4">
                {/* <input
              type="text"
              // pattern="[0-9]{5}"
              // maxLength={"3"}
              className={`form-control offerCode mr-1 `}
              placeholder="Adhoc Charge"
              // value={formData?.adhocChargeName}
              // onChange={(e) => {
              //   setFormData({ ...formData, adhocChargeName: e.target.value });
              //   // orderUIContext.dispatchReducer({
              //   //   type: "adhocChargeName",
              //   //   value: e.target.value,
              //   // });
              // }}
            /> */}
                {/* </div> */}
                {/* <div className="form-inline"> */}
                {/* <input
              type="text"
              pattern="[0-9]{5}"
              maxLength={"5"}
              style={{ width: "72px" }}
              className={`form-control  ml-2`}
              placeholder="£ 0.00"
              // value={formData?.adhocPrice}
              // onChange={(e) => {
              //   if (e.target.value === "+" && e.target.value === "0") return;
              //   setFormData({ ...formData, adhocPrice: e.target.value });
              // }}
            /> */}
                {/* </div>
          <div className="form-inline" style={{ width: "100%" }}> */}
                {/* <button
              type="submit"
              // style={{ width: "27%" }}
              className="btn btn-primary font-weight-bolder font-size-sm mr-3  ml-2 "
              // onClick={handleAdHoc}
              // disabled={
              //   formData.adhocPrice && Number(formData.adhocPrice) > 0
              //     ? false
              //     : true
              // }
            >
              {intl.formatMessage({ id: "Add.Charge" })}
            </button> */}
              </div>
            </div>
            <div className="col-4">
              <FormControl fullWidth>
                <label>
                  <strong>Reason for Return</strong>
                </label>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="form-control"
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  // onChange={(e) => {
                  //   setFieldValue("search_view", e.target.value);
                  //   handleSubmit();
                  // }}
                  name="return_reason"
                  {...formik.getFieldProps("return_reason")}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="Customer Requested">
                    Customer Requested
                  </MenuItem>
                  <MenuItem value="Disposed">Disposed</MenuItem>
                  <MenuItem value="Lost to competitor">
                    Lost to competitor
                  </MenuItem>
                  <MenuItem value="Price sensitive">Price sensitive</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.return_reason && formik.errors.return_reason ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.return_reason}
                  </div>
                </div>
              ) : null}

              <textarea
                type="text"
                name="return_reason_description"
                placeholder={"Description"}
                label="Desc"
                className={`form-control mt-2  `}
                maxLength={"100"}
                {...formik.getFieldProps("return_reason_description")}
              />
              {formik.touched.return_reason_description &&
                formik.errors.return_reason_description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.return_reason_description}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
