export const getPruductObject = (item) => {
  const data = {
    item_price_id: item?.item_price_id,
    quantity: item?.quantity || 1,
    id: item?.product_id,
    name: item?.external_name || item?.product_name || item.product_id || "",
    price: item?.unit_price / 100 || item?.amount / 100 || "0",
    type: item?.item_type,
    amount: item?.amount / 100,
    pricing_model: item?.pricing_model || "",
  };

  return data;
};

export const getBreadDownPayload = (state) => {
  const dataObject = {
    franchise_id: state?.orderData?.franchise_id,
    subscription_id: state?.orderData?.subscription_id,
    coupon_id: [],
    apply_manual_discounts: state?.manualDiscount
      ? state?.manualDiscount.filter(
          (item) =>
            (item.operation_type === "remove" && item?.type === "old") ||
            (item.operation_type === "add" && item?.type !== "old")
        )
      : [],
    items: state?.productArray,
    subscription_billing_period: state?.orderData?.subscription_billing_period,
    subscription_billing_period_unit:
      state?.orderData?.subscription_billing_period_unit,
    // ...state.applyChanges,
    end_of_term: state.applyChanges?.end_of_term ? true : false,
    invoicing_option: state.applyChanges?.invoicing_option ? true : false,
    status: state?.orderData?.status || "",
    // prorate: state.applyChanges?.prorate ? true : false,
  };
  if (!state.applyChanges?.end_of_term) {
    dataObject.prorate = state.applyChanges?.prorate ? true : false;
  }
  if (state?.coupon?.length > 0) {
    const couponArr = state?.coupon?.map((item, index) => {
      return item?.id;
    });
    dataObject.coupon_id = couponArr;
  }
  return dataObject;
};

export const getUpdateSubscriptionPayload = (state) => {
  const dataObject = {
    order_id: state?.orderData?.order_id,
    franchise_id: state?.orderData?.franchise_id,
    invoicing_option: state.applyChanges?.invoicing_option ? true : false,

    subscription_id: state?.orderData?.subscription_id,
    // prorate: true,
    end_of_term: state.applyChanges?.end_of_term ? true : false,

    coupon_id: [],
    apply_manual_discounts: state?.manualDiscount
      ? state?.manualDiscount.filter(
          (item) =>
            (item.operation_type === "remove" && item?.type === "old") ||
            (item.operation_type === "add" && item?.type !== "old")
        )
      : [],
    items: state?.productArray,
    subscription_billing_period: state?.orderData?.subscription_billing_period,
    subscription_billing_period_unit:
      state?.orderData?.subscription_billing_period_unit,
    status: state?.orderData?.status || "",
  };
  if (!state.applyChanges?.end_of_term) {
    dataObject.prorate = state.applyChanges?.prorate ? true : false;
  }
  if (state?.coupon?.length > 0) {
    const couponArr = state?.coupon?.map((item, index) => {
      return item?.id;
    });
    dataObject.coupon_id = couponArr;
  }
  dataObject.packaging_materials =
    // eslint-disable-next-line eqeqeq
    state?.productArray?.filter((res) => res?.is_packaging_material == 1) || [];
  const planSelect = state?.productArray.find((e) => e.type === "plan");
  if (planSelect) {
    dataObject.pod_plan_type =
      planSelect?.id === "Economy-POD"
        ? "Economy"
        : planSelect?.id === "POD"
        ? "Premium"
        : planSelect?.id === "First-Class"
        ? "First Class"
        : "";
    dataObject.bp_id = planSelect?.id;
  }
  return dataObject;
};

export const calculatePriceAccToProduct =  (
  productArr,
  productId,
  quantity
) => {
  const resolvedProductArr =  productArr;

  const product = resolvedProductArr.find(
    (e) => e.stripe_product_id === productId
  );

  if (!product) {
    throw new Error("Product not found");
  }

  if (quantity < 7) {
    if (product.product_prices.length > 2) {
      return (
        Number(product.product_prices[quantity - 1].unit_amount) 
      );
    } else {
      return Number(product.product_prices[0].unit_amount) * quantity;
    }
  } else {
    if (product.product_prices.length > 2) {
      const qty = quantity - 6;
      console.log("product", product);
      return (
        Number(product.product_prices[6].unit_amount) * qty +
        Number(product.product_prices[5].unit_amount)
      );
    } else {
      return Number(product.product_prices[0].unit_amount) * quantity;
    }
  }
};
