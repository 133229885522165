import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ReturnReasonModal } from "../modals/ReturnReasonModal";
import { findOfferCode } from "../../_redux/order/orderCrud";
import { OrderPayment } from "../../order-canva/OrderPayment";
import { OrderPaymentStripe } from "../../order-canva/OrderPaymentStripe";
import { transformDataToAddProduct } from "../NewOrderStripeUtils";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
export const OrderSummaryButton = ({
  state,
  dispatchReducer,
  orderSummaryListStripe,
  setSendQuoteCanva,
  sendQuoteCanva,
}) => {
  const [promoCodeText, setPromoCodeText] = useState(null);
  const [openCanvaQuote, setOpenCanvaQuote] = useState({ open: false });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleOfferCode = (applyCode, franchise) => {
    setSnack({ call: false, type: "", message: "" });
    const arr = [...state?.promoCoupon];
    const checkExists = arr.some((e) => e.id === applyCode);
    if (checkExists) {
      setSnack({
        call: true,
        type: "error",
        message: "Coupon already applied",
      });
      return;
    }
    findOfferCode(applyCode, franchise)
      .then((res) => {
        if (res.data.data.percent_off !== null) {
          arr.push({
            type: "percent",
            id: res.data.data.id,
            value: res.data.data.percent_off,
            name: applyCode,
            isDeletable: true,
          });
        } else {
          arr.push({
            type: "fixed",
            id: res.data.data.id,
            value: +res.data.data.amount_off/100,
            name: applyCode,
            isDeletable: true,
          });
        }
        // if (res.data.data[0].coupon.discount_type === "percentage") {
        //     const val = res.data.data[0].coupon;
        //     const check =
        //         val?.item_constraints?.some(
        //             (e) => e.item_type === "plan" && e.constraint === "all"
        //         ) || false;
        //     if (val?.apply_on === "each_specified_item" && check) {
        //         arr.push({
        //             type: "percent",
        //             id: res.data.data[0].coupon.id,
        //             value: res.data.data[0].coupon.discount_percentage,
        //             name: applyCode,
        //             check: "billing",
        //             isDeletable: true,
        //         });
        //     } else {
        //         arr.push({
        //             type: "percent",
        //             id: res.data.data[0].coupon.id,
        //             value: res.data.data[0].coupon.discount_percentage,
        //             name: applyCode,
        //             isDeletable: true,
        //         });
        //     }
        // } else {
        //     const val = res.data.data[0].coupon;
        //     const check =
        //         val?.item_constraints?.some(
        //             (e) => e.item_type === "plan" && e.constraint === "all"
        //         ) || false;
        //     if (val?.apply_on === "each_specified_item" && check) {
        //         arr.push({
        //             type: "fixed",
        //             id: res.data.data[0].coupon.id,
        //             value: res.data.data[0].coupon.discount_amount,
        //             name: applyCode,
        //             check: "billing",
        //             isDeletable: true,
        //         });
        //     } else {
        //         arr.push({
        //             type: "fixed",
        //             id: res.data.data[0].coupon.id,
        //             value: res.data.data[0].coupon.discount_amount,
        //             name: applyCode,
        //             isDeletable: true,
        //         });
        //     }
        // }
        console.log("log-8", arr);
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, promoCoupon: arr },
        });
        // setSnack({
        //   call: true,
        //   type: "success",
        //   message: res.data.message,
        // });
        setPromoCodeText("");
      })
      .catch((error) => {
        // setSnack({
        //   call: true,
        //   type: "error",
        //   message: error?.response?.data?.message,
        //   error: error?.response?.data?.errors,
        // });
      });
  };
  const handleAddAdHoc = () => {
    const adhocCharge = orderSummaryListStripe.allProducts?.find(
      (e) => e.stripe_product_id === process.env.REACT_APP_PRODUCT_ADHOC_CHARGE
    );
    const data = transformDataToAddProduct(adhocCharge);
    data.cost = state?.adhocGbpDummy;
    data.total = state?.adhocGbpDummy;
    const arr = [...state.product_array, data];
    dispatchReducer({
      type: "SET_DATA",
      data: { ...state, product_array: arr, set: 10 },
    });
  };
  return (
    <>
      <div id="btn-neworderpage">
        <div className="row">
          <div className="col-4">
            <input
              type="text"
              // pattern="[0-9]{5}"
              // maxLength={"3"}
              className={`form-control  mt-2`}
              placeholder="Adhoc Charge"
              value={state?.adhocChargeName}
              onChange={(e) => {
                dispatchReducer({
                  type: "SET_DATA",
                  data: { ...state, adhocChargeName: e.target.value },
                });
              }}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              pattern="[0-9]{5}"
              maxLength={"5"}
              className={`form-control  mt-2`}
              placeholder="£ 0.00"
              value={state?.adhocGbpDummy}
              onChange={(e) => {
                if (e.target.value === "+") return;
                dispatchReducer({
                  type: "SET_DATA",
                  data: { ...state, adhocGbpDummy: e.target.value },
                });
              }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary font-weight-bolder font-size-sm mt-2  col-4 "
            onClick={() => handleAddAdHoc()}
            disabled={
              state?.adhocGbpDummy && state?.adhocGbpDummy != "" ? false:true
            }
          >
            {/* {intl.formatMessage({ id: "Add.Charge" })} */}
            Add Charge
          </button>
          <div className="col-4">
            <input
              type="text"
              className={`form-control  mt-3 `}
              placeholder={`Offer Code`}
              name="offerCode"
              value={promoCodeText}
              maxLength="40"
              onChange={(e) => setPromoCodeText(e.target.value)}
              //   disabled={state.productArray.some((e)=>e.id === "Howdens")}
            />
            {/* {state?.coupon_id?.some((e) => e.name === applyCode) && ( */}
            <span style={{ color: "red" }}>
              {/* {intl.formatMessage({ id: "Already.used" })} */}
              {/* Already used */}
            </span>
            {/* )} */}
          </div>
          <div className="col-4">
            <button
              type="button"
              onClick={() =>
                handleOfferCode(promoCodeText, state?.franchise_id)
              }
              className="btn btn-primary font-weight-bolder font-size-sm  mt-3 w-100"
              //   disabled={
              //     applyCode === "" ||
              //     state?.coupon_id?.some((e) => e.name === applyCode)
              //   }
            >
              {/* {intl.formatMessage({ id: "Apply.Code" })} */}
              Apply Code
            </button>
            {/* {formik.touched.offerCode && formik.errors.offerCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.offerCode}</div>
              </div>
            ) : null} */}
          </div>
          <Dropdown
            className="col-4 dropdown-inline"
            style={{ padding: 0 }}
            drop="down"
            alignRight
          >
            <Dropdown.Toggle
              variant="transparent"
              className="btn btn-primary font-weight-bolder font-size-sm dropdown-toggle  mt-3 w-100 "
              id="dropdown-toggle-top2"
            >
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              {console.log("mohit-90", state)
              }
              <Dropdown.Item
                disabled={
                  state?.containerTypeSelect === "container"
                    ? !state.collectionRetrun?.collection_date
                    : !state.collectionRetrun?.start_time_slot
                }
                onClick={() => setOpenCanvaQuote({ open: true })}
              >
                Make Payment
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => setSendQuoteCanva({ type: "quote", open: true })}
                // disabled={state.competitorPrice?.check}
              >
                {/* {intl.formatMessage({ id: "Send.Quote" })} */}
                Send Quote
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={() =>
              //   setOpenCanvaQuote({ type: "draft", open: true })
              // }
              // disabled={state.competitorPrice?.check}
              >
                {/* {intl.formatMessage({ id: "Save.Draft" })} */}
                Save Draft
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="adhoc-charge mt-2"></div>
      </div>
      <div className="summer-footer w-100 justify-content-end"></div>
      <ReturnReasonModal
      //   setShow={setShow} show={show}
      />
      {openCanvaQuote.open ? (
        <>
          <OrderPaymentStripe
            state={state}
            dispatchReducer={dispatchReducer}
            setOpenCanvaQuote={setOpenCanvaQuote}
            // snack={snack}
            // setSnack={setSnack}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
          ></div>
        </>
      ) : (
        ""
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
