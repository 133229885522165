/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderTimeline,
  fetchOrderDropDown,
  fetchOrderInvoice,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { RefundSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { creditNotes, refund, refundCredit } from "../_redux/order/orderCrud";
import { useFormik } from "formik";

export function Refund({ setCanva, canva, orderDetailData }) {
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDropDown,
    }),
    shallowEqual
  );
  console.log("Canmnnvaascadc ", canva);
  console.log("ORDER DETAILSSSSS", orderDetail);

  const initialValues = {
    franchise_id: canva?.data?.franchise_id || "",
    invoice_id: canva?.data?.invoice_id || "",
    amount:
      canva?.data.invoice_type === "Credit Note" &&
      canva?.data?.status === "refund_due"
        ? canva?.data?.amount_available
        : canva?.data?.amount || "",
    reason_code: "",
    notes: "",
    type: "refundable",
    order_id: canva?.data?.order_id || "",
    currency_code: canva?.data?.currency || "",
  };

  const handleSubmit = (values, actions) => {
    console.log("Submit Values", values);
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    // const refundFun =
    //   canva?.data?.invoice_type === "Credit Note" &&
    //   canva?.data?.status === "refund_due"
    //     ? refundCredit
    //     : refund;
    // const creditData = {
    //   credit_note_id: values.invoice_id,
    //   amount: values.amount,
    //   order_id: values.order_id,
    //   franchise_id: values.franchise_id,
    //   comment: values.notes,
    // };
    creditNotes(
      values
      // canva?.data?.invoice_type === "Credit Note" ? creditData : values
    )
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        dispatch(
          fetchPaymentInvoice(
            canva?.data?.order_id,
            canva?.data?.franchise_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchOrderInvoice(canva?.data?.order_id));
        dispatch(
          fetchInvoiceList(
            canva?.data?.franchise_id,
            canva?.data?.order_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(canva?.data?.order_id));
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema:
      // canva?.data?.invoice_type === "Credit Note" &&
      // canva?.data?.status === "refund_due"
      canva?.data?.invoice_type === "Credit Note" ? false : RefundSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    dispatch(fetchOrderDropDown());
  }, [dispatch]);
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: "" });
      formik.resetForm();
    }
  };
  return (
    <>
      {/*begin::Header*/}

      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Refund</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_Refund_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  Refund Amount for{" "}
                  {canva?.data?.invoice_type === "Credit Note" &&
                  canva?.data?.status === "refund_due"
                    ? "Credit note"
                    : "invoice"}{" "}
                  #{canva?.data?.invoice_id || "-"}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={canva?.data?.currency || ""}
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {canva?.data?.invoice_type === "Credit Note" &&
            canva?.data?.status === "refund_due" ? (
              ""
            ) : (
              <div>
                <Form.Group className="mb-5">
                  <Form.Label>Reason code</Form.Label>
                  <FormControl fullWidth>
                    <Select
                      name="reason_code"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      className={`form-control w-100 ${getInputClasses(
                        "reason_code"
                      )}`}
                      {...formik.getFieldProps("reason_code")}
                    >
                      <MenuItem value="">Reason Code</MenuItem>
                      {orderDetail &&
                      orderDetail?.reasonCodeList &&
                      orderDetail.reasonCodeList.length &&
                      orderDetail.reasonCodeList.length > 0 ? (
                        orderDetail.reasonCodeList.map((item, index) => (
                          <MenuItem value={item?.id} key={index}>
                            {item?.title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="no"></MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {formik.touched.reason_code && formik.errors.reason_code ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.reason_code}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
            )}
            <div>
              <Form.Group className="mb-5">
                {/* <Form.Label>Reason code</Form.Label> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Add a note (optional)"
                  className={`form-control  ${getInputClasses("notes")}`}
                  name="notes"
                  {...formik.getFieldProps("notes")}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.notes}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right  mr-4">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Refund
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
