/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */

import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { LinkFranchiseCanva } from "../../product-canvas/LinkFranchise";
import { LoadingDialog } from "../../../../../../../_metronic/_partials/controls";
import NewBreadCrumbs from "../../../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { PricingCanvas } from "../../product-canvas/Pricing";
import ProductDetailCard from "./ProductDetailCard";
import {
  fetchMyProductTimeline,
  fetchProductDetail,
} from "../../../../_redux/products/productsActions";
import { initIds } from "../../../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../../../../../../_metronic/_partials/controls/LoadingSpinner";
import { productsSlice } from "../../../../_redux/products/productsSlice";
export function ProductDetails(props) {
  const { actions } = productsSlice;
  const init = ["kt_link_franchise_panel", "kt_plan_panel"];
  const intitt = ["kt_pricing_panel"];
  // Use the 'useIntl' hook for internationalization
  const intl = useIntl();
  // Use the 'useDispatch' hook to dispatch 'fetchProductDetail' action
  const dispatch = useDispatch();
  const params = useParams();
  // Use the 'useState' hook to manage the 'canva' state
  const [canva, setCanva] = useState({ type: false, data: {} });

  // Use the 'useSelector' hook to access the 'detailData' state
  const { detailData, timeline, role } = useSelector(
    (state) => ({
      detailData: state.products.detail,
      role: state.auth.user.roles,
      timeline: state.products.timeline,
    }),
    shallowEqual
  );
  // Use the 'useEffect' hook to fetch product details when the 'id' parameter changes
  useEffect(() => {
    if (props?.match?.params?.p_id) {
      dispatch(
        fetchProductDetail(props?.match?.params?.p_id, props?.match?.params?.id)
      );
    } else {
      dispatch(fetchProductDetail(props?.match?.params?.id));
    }
    return () => {
      dispatch(actions.productDetailFetched({ data: { loading: true } }));
    };
  }, [dispatch, props.match.params.id, props?.match?.params?.p_id]);

  // Use the 'useEffect' hook to initialize the 'init' and 'intitt' arrays
  useEffect(() => {
    if (!detailData?.loading) {
      initIds(init);
    }
    initIds(intitt);
  }, [detailData.loading, init, intitt]);

  useEffect(() => {
    if (detailData && detailData.id) {
      dispatch(fetchMyProductTimeline(detailData?.id));
    }
  }, [detailData]);
  console.log("timelineeeee", timeline);

  return (
    <>
      {/* Display breadcrumbs with a title of "Product.Details" */}
      <NewBreadCrumbs title={intl.formatMessage({ id: "Product.Details" })} />
      <div className="row">
        {/* Display the product details */}
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card card-custom  gutter-b mb-10">
            <div className="card-body">
              {/* If the product details are loading, display a loading dialog */}
              {detailData?.loading ? (
                <LoadingSpinner />
              ) : (
                <ProductDetailCard
                  setCanva={setCanva}
                  detailData={detailData}
                  timeline={timeline}
                />
              )}
              {/* Display a button to toggle the franchise link */}
              {/* {role && role?.length && role[0] === "super-admin" ? (
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <div id="kt_link_franchise_panel_toggle">
                    {detailData?.loading ? (
                      ""
                    ) : (
                      <button className="btn btn-primary btn-sm font-weight-bold">
                        {intl.formatMessage({ id: "Link.Franchise" })}
                      </button>
                    )}
                  </div>
                  <div></div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
        {/* Display metrics */}
        {/* <div className="col-lg-12 col-xl-6">
          <Metrics filterBtn={false} noOfChart={false} />
        </div> */}
      </div>
      {/* Display the franchise link */}
      <LinkFranchiseCanva detailData={detailData} />
      {/* Display the pricing canvas */}
      {canva.type ? (
        <>
          <PricingCanvas setCanva={setCanva} canva={canva} />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanva({ type: false, data: {} })}
          ></div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
